<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import store from '@/store'
export default {
  data() {
    return {}
  },
  created() {
    if (this.getQueryString('token')) {
      console.log(this.getQueryString('token'));
      window.localStorage.setItem("token", this.getQueryString('token'));
    }
  },
  mounted() {
    // T- 移动端 F- PC端
    if (!this.isMobileUserAgent()) {
      console.log('PC端')
      // this.$router.replace('/agencyRegister');
    } else {
      console.log('手机端');
      this.$router.replace('/h5Index');
    }
  },
  methods: {
    getQueryString(key) {
      const url = location.href
      // 获取 hash 值，不包含 '#' 号
      const hash = url.substring(url.indexOf("#") + 1)
      // 查找 '?' 号所在的索引
      const searchIndex = hash.indexOf("?")
      // '?' 号后面接的是索引参数，如果找到则+1，去除'?' 号
      const search = searchIndex !== -1 ? hash.substring(searchIndex + 1) : ""
      // 把搜索参数字符串提过URLSearchParams转成对象形式
      const usp = new URLSearchParams(search)
      // 通过URLSearchParams自带的get方法，查询键所对应的值
      return usp.get(key)
    },
    isMobileUserAgent() { // 判断是pc端还是h5端
      return /iphone|ipod|android|windows.*phone|blackberry.*mobile/i.test(
        window.navigator.userAgent.toLowerCase()
      );
    },
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
</style>
