import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/reset.css";
import "@/assets/css/border.css";
import "@/assets/css/hover.css";
import "@/assets/fonts/fonts.css";
import Vant from "vant";
import "vant/lib/index.css";
import animated from 'animate.css' // npm install animate.css --save安装，在引入

Vue.use(animated)
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Vant);
// HTTP
import http from '@/request/api'
Vue.prototype.$http = http

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");