import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
// 路由点击两次时报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

const routes = [
	//login
	{
		path: "/",
		name: "login",
		redirect: "/login",
		component: () => import("@/views/user/login.vue"),
		children: [{
			path: '/login',
			name: 'login',
			component: () => import("@/views/user/login.vue"),
		}]
	}, {
		path: "/home",
		name: "index",
		redirect: "/home",
		component: () => import("@/views/agencyRegister/index.vue"),
		children: [{
				path: '/home',
				name: 'home',
				component: () => import("@/views/agencyRegister/home.vue"),
			},
			{
				path: '/identity',
				name: 'identity',
				component: () => import("@/views/agencyRegister/identity.vue"),
			},
			{
				path: '/agencyRegister/createShop',
				name: 'createShop',
				component: () => import("@/views/agencyRegister/createShop.vue"),
			},
			{
				path: '/agencyRegister/helipayAuthentication',
				name: 'helipayAuthentication',
				component: () => import("@/views/agencyRegister/helipayAuthentication.vue"),
			},
			{
				path: '/agencyRegister/uacAuthentication',
				name: 'uacAuthentication',
				component: () => import("@/views/agencyRegister/uacAuthentication.vue"),
			},
			// {
			//   path: "/onlineUpdate",
			//   name: "onlineUpdate",
			//   redirect: "/onlineUpdate/uploadFiles",
			//   component: () => import("@/views/onlineUpdate.vue"),
			//   children: [
			//     {
			//       path: "uploadFiles",
			//       component: () => import("@/components/uploadFiles.vue")
			//     },
			//   ]
			// }
		]
	},
	{
		path: "/user",
		name: "user",
		component: () => import("@/views/user/index.vue"),
		children: [{
			path: '/identityInfo',
			name: 'identityInfo',
			component: () => import("@/views/user/identityInfo.vue"),
		}, ]
	},
	//h5路由
	{
		path: "/h5Index",
		name: "h5Index",
		component: () => import("@/views/mobel/home.vue"),
	},
];

const router = new VueRouter({
	mode: 'hash', // 需要后端支持
	routes,
});

export default router;
