import axios from "axios"; // 引入axios
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// import QS from "qs"; // 引入qs模块，用来序列化post类型的数据，后面会提到
// import store from "@/store/index";
import router from "@/router";

// 环境的切换
// http://192.168.0.54:8889
// if (process.env.NODE_ENV == "development") {
//   axios.defaults.baseURL = "118.190.37.229/api";
// } else if (process.env.NODE_ENV == "debug") {
//   axios.defaults.baseURL = "/api";
// } else if (process.env.NODE_ENV == "production") {
//   axios.defaults.baseURL = "/api";
// }
// if (process.env.NODE_ENV == "development") {
//   axios.defaults.baseURL = "/api";
// } else if (process.env.NODE_ENV == "debug") {
//   axios.defaults.baseURL = "/api";
// } else if (process.env.NODE_ENV == "production") {
//   axios.defaults.baseURL = "/api";
// }
axios.defaults.baseURL = "/api"

// 请求超时
// axios.defaults.timeout = 300000;
// 默认请求头
// axios.defaults.headers["''"] = true;
// axios.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded;charset=UTF-8";

// 请求拦截器
axios.interceptors.request.use(
  config => {
    let isNotToken =  false;
    let token = window.localStorage.getItem("token");
    // let token = 'ab2836c0-e243-4798-a83c-4b39017104c3';
	if (config.url.indexOf('getPublicKey') != -1 || config.url.indexOf('/oauth/token') != -1) {
		isNotToken = true
        config.headers.Authorization = "Basic Y2luZGVyZWxsYTpjaW5kZXJlbGxh"
	}
    if (!token && !isNotToken) {
      ElementUI.Message({
        message: '登陆状态已失效，请重新登陆',
        type: "succwarningess"
      });
      // setTimeout(() => {
      //   window.location.href = 'https://preprod.bmark.cn/login/page/index?status=login'
      // }, 3000);
    }
	if(!isNotToken) {
		token && (config.headers.Authorization = 'Bearer ' + token);
	}
    return config;
  },
  error => {
    return Promise.error(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(res => {
  // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
  // 否则的话抛出错误
  if (res.status === 200) {
    return Promise.resolve(res);
  } else {
    return Promise.reject(res);
  }
});

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then(res => {
        if (res.status == 200 && res.data.code != 0) {
          // ElementUI.Message.error(res.data.msg);
        }
        resolve(res.data);
      })
      .catch(err => {
		if (err.response.status === 401) {
			router.push('/login')
			// window.open('https://china.bmark.cn/login/page/index?status=login','_self');
		}
        ElementUI.Notification.error(err.message);
        reject(err.data || err.response.data);
      });
  });
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(res => {
        if (res.status == 200 && res.data.code != 0) {
          // ElementUI.Message.error(res.data.msg);
        }
        resolve(res.data);
      })
      .catch(err => {
        ElementUI.Notification.error(err.message);
        reject(err.data);
      });
  });
}
// post下载excel文件
export function postExcel(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, { responseType: "blob" })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        ElementUI.Notification.error(err.message);
        reject(err.data);
      });
  });
}

export function del(url, params) {
  return new Promise((resolve, reject) => {
    console.log(params);
    axios
      .delete(url, {
        data: params
      })
      .then(res => {
        if (res.status == 200 && res.data.code != 0) {
          ElementUI.Message.error(res.data.msg);
        }
        resolve(res.data);
      })
      .catch(err => {
        ElementUI.Notification.error(err.message);
        reject(err.data);
      });
  });
}

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      res => {
        if (res.status == 200 && res.data.code != 0) {
          ElementUI.Message.error(res.data.msg);
        }
        resolve(res.data);
      },
      err => {
        ElementUI.Notification.error(err.message);
        reject(err);
      }
    );
  });
}
export function upload(url, md5Base64, file, contentType) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, file, {
        headers: {
          "Content-Md5": md5Base64,
          "Content-Type": contentType
        }
      })
      .then(
        res => {
          if (res.status == 200 && res.data.code != 0) {
            // ElementUI.Message.error(res.data.msg);
          }
          resolve(res);
        },
        err => {
          console.log(err);
          ElementUI.Notification.error(err.message);
          reject(err);
        }
      );
  });
}

export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(
      res => {
        if (res.status == 200 && res.data.code != 0) {
          ElementUI.Message.error(res.data.msg);
        }
        resolve(res.data);
      },
      err => {
        ElementUI.Notification.error(err.message);
        reject(err);
      }
    );
  });
}
